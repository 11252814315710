@use '/src/assets/new_variables.scss' as *;
@media print {
  .footer_container {
    display: none !important;
  }
}
.footer_container {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 700px;
  padding-bottom: unset;
  padding-top: 66px;
  justify-content: center;
  align-items: center;
  background: $color-primary;
  padding-top: 66px;
  .upper_section {
    display: flex;
    flex: 2 1;
    justify-content: space-between;
    padding: 0 60px;
    max-width: 1920px;
    width: 100%;
    .logo_container {
      flex: 1;
      @media screen and (min-width: 1280px) and (max-width: 1500px) {
        flex: 0;
      }
      .logo {
        width: 350px;
        height: 144px;
        @media screen and (min-width: 1280px) and (max-width: 1500px) {
          height: 120px;
          width: 300px;
        }
      }
    }
    .links {
      flex: 2;
      display: flex;
      justify-content: space-evenly;
      @media screen and (min-width: 1280px) and (max-width: 1500px) {
        margin-left: 20px;
      }
      .link_colom {
        @media screen and (min-width: 1280px) {
          margin-right: 20px;
          margin-left: 20px;
          max-width: 220px;
        }
        .link_header {
          color: $color-white;
          font-family: $font-primary;
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: 0.52px;
          margin-bottom: 20px;
        }
        .link_item {
          list-style-type: none;
          margin: unset;
          padding: unset;
          .link_li {
            margin-bottom: 20px;
            .link {
              color: $color-white;
              font-size: 19px;
              font-family: $font-secondry;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-decoration: none;
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
              @media screen and (min-width: 1280px) and (max-width: 1500px) {
                font-size: 17px;
              }
            }
          }
        }
      }
    }
  }
  .mid_section {
    align-items: center;
    color: $color-white;
    display: flex;
    flex: 1 1;
    height: auto;
    justify-content: space-between;
    min-height: 140px;
    padding: 0 60px 50px;
    max-width: 1920px;
    width: 100%;
    &.al-end {
      justify-content: end;
    }

    .input_set {
      height: 133px;
      display: flex;
      flex-direction: column;
      font-family: $font-primary;
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.4px;
      .united_actions {
        display: flex;
        margin-top: 20px;
        .form-rq-control-container {
          position: relative;
          .clean-input {
            position: absolute;
            top: 20px;
            right: 25px;
            cursor: pointer;
          }
          .error-message {
            color: $color-error-input;
            position: absolute;
            top: 37px;
            left: 20px;
            line-height: 17px;
            pointer-events: none;
            .input-error {
              font-size: 18px;
              font-family: $font-secondry;
              font-weight: 500;
            }
            .error-field-text {
              font-size: 14px;
              font-family: $font-secondry;
              font-weight: 400;
            }
          }
          .form-rq-control {
            border-radius: 10px;
            margin-right: 10px;
            width: 300px;
            height: 60px;
            font-family: $font-secondry;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            border: 1px solid $color-link-water;
            padding: 0px 40px 0px 20px;
            color: $color-primary;
            outline: 1px solid $color-link-water;
            &.error-input {
              color: $color-error-input;
              padding-bottom: 2px;
            }
          }
          label {
            position: absolute;
            pointer-events: auto;
            font-size: 18px;
            font-family: $font-secondry;
            transition: top 0.3s ease, left 0.3s ease, color 0.3s ease;
            font-weight: 400;
            &.label-in-input {
              color: $color-primary;
              top: 19px;
              left: 20px;
              cursor: text;
            }
            &.label-above-input {
              color: $color-white;
              top: -22px;
              left: 10px;
            }
          }
        }
      }
    }
    .img_buttons {
      display: flex;
      width: 265px;
      justify-content: space-evenly;
      align-self: end;
    }
  }
  .lower_section {
    align-items: center;
    border-top: 1px solid $color-chambray;
    display: flex;
    flex: 1 1;
    height: auto;
    min-height: 100px;
    padding: 0 60px;
    max-width: 1920px;
    width: 100%;
    @media screen and (min-width: 1280px) and (max-width: 1500px) {
      flex-direction: column;
      padding: 20px 60px;
    }
    .links {
      .static-text {
        color: $color-white;
        font-family: $font-secondry;
      }
      display: flex;
      list-style-type: none;
      flex: 2;
      justify-content: space-between;
      margin-right: 216px;
      @media screen and (min-width: 1280px) and (max-width: 1500px) {
        margin-right: 0px;
      }
      li {
        color: $color-white;
        font-family: $font-secondry;
        @media screen and (min-width: 1280px) and (max-width: 1500px) {
          white-space: nowrap;
          margin-right: 10px;
          margin-left: 10px;
        }
        a {
          color: $color-white;
          font-family: $font-secondry;
          text-decoration: none;
          cursor: pointer;
        }
        a:hover {
          border-bottom: solid 1px;
        }
      }
    }
    .icon_links {
      display: flex;
      flex: 1;
      justify-content: space-between;
      width: 416px;
      height: 26px;
      cursor: pointer;
      .icon_img {
        transition: transform 0.1s ease-in-out;
      }
      .icon_img:hover {
        transform: scale(1.1);
      }
    }
  }
  .white_bottom {
    background: $color-white;
    flex: 0;
    color: $color-primary;
    text-align: center;
    font-family: $font-secondry;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-right: 340px;
    padding-left: 340px;
    padding-top: 26px;
    padding-bottom: 10px;
    text-wrap: balance;
    min-height: 70px;
    height: auto;
    width: 100%;
  }
  #arrowBtn {
    background: url('../../../assets/images/arrow-mail-input.png');
    border-radius: 14px;
    width: 60px;
    height: 60px;
    border: 0;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
  }
  #downloadApp-1 {
    width: 135px;
    height: 40px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    outline: none;
    background-repeat: no-repeat !important;
  }
  #downloadApp-2 {
    width: 117px;
    height: 40px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    outline: none;
    background-repeat: no-repeat !important;
  }
  #downloadApp-3 {
    width: 135px;
    height: 40px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    outline: none;
    background-repeat: no-repeat !important;
  }
  #downloadApp-4 {
    width: 135px;
    height: 40px;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    outline: none;
    background-repeat: no-repeat !important;
  }
  & .inputNew {
    margin: 0 10px 0 0;

    & label {
      color: $color-primary;
    }
  }
  & .captcha-box {
    & label {
      color: $color-white;
    }
  }

  & .footer-newsletter {
    & .inputNew .outlineInput {
      background-color: $color-white;
    }

    & .consent-row {
      margin-top: 10px;
      & .col-1 {
        width: 30px;
        margin-right: 10px;
        & .c-check {
          & input[type='checkbox'] {
            margin-top: 5px;
            width: 30px;
            height: 30px;
            border: 1.5px solid $color-link-water;
            border-radius: 5px;
          }
          & input:checked[type='checkbox'] {
            border: 0;
            background-color: $color-white;
            background-image: url('../../../assets/images/checkbox-checked-blue.png');
            background-size: auto;
          }
        }
      }
      & .c-desc {
        width: 415px;
        font-family: $font-secondry;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }
}
.footer_mobile_container {
  background: $color-primary;
  min-height: 700px;
  height: auto;
  display: flex;
  flex-direction: column;
  .img_buttons {
    @media screen and (min-width: 720px) and (max-width: 1280px) {
      justify-content: flex-start;
      padding: 15px 0px 0px 15px;
      margin-top: 0;
    }
    width: 100%;
    margin-top: 15px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    #downloadApp-1 {
      width: 135px;
      height: 40px;
      border-radius: 8px;
      margin-left: 10px;
      margin-right: 10px;
      border: none;
      outline: none;
      background-repeat: no-repeat !important;
    }
    #downloadApp-2 {
      width: 117px;
      height: 40px;
      border-radius: 8px;
      margin-left: 10px;
      margin-right: 10px;
      border: none;
      outline: none;
      background-repeat: no-repeat !important;
    }
    #downloadApp-3 {
      width: 135px;
      height: 40px;
      border-radius: 8px;
      margin-left: 10px;
      margin-right: 10px;
      border: none;
      outline: none;
      background-repeat: no-repeat !important;
    }
    #downloadApp-4 {
      width: 135px;
      height: 40px;
      border-radius: 8px;
      margin-left: 10px;
      margin-right: 10px;
      border: none;
      outline: none;
      background-repeat: no-repeat !important;
    }
  }
  .input_set {
    display: flex;
    flex-direction: column;
    font-family: $font-primary;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1px;
    color: $color-white;
    padding: 10px 20px 0px 25px;
    .united_actions {
      display: flex;
      margin-top: 20px;
      .form-rq-control-container {
        position: relative;
        width: 300px;
        .clean-input {
          position: absolute;
          top: 15px;
          right: 15px;
          cursor: pointer;
        }
        .error-message {
          color: $color-error-input;
          position: absolute;
          top: 33px;
          left: 20px;
          line-height: 17px;
          pointer-events: none;
          .input-error {
            font-size: 18px;
            font-family: $font-secondry;
            font-weight: 500;
          }
          .error-field-text {
            font-size: 14px;
            font-family: $font-secondry;
            font-weight: 400;
          }
        }
        .form-rq-control {
          border-radius: 10px 0px 0px 10px;
          margin-right: 10px;
          width: 100%;
          height: 50px;
          font-family: $font-secondry;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          border: 0;
          padding: 0px 40px 0px 20px;
          color: $color-primary;
          outline: 0;
          &.error-input {
            color: $color-error-input;
            padding-bottom: 3px;
          }
        }
        label {
          position: absolute;
          pointer-events: auto;
          font-size: 18px;
          font-family: $font-secondry;
          transition: top 0.3s ease, left 0.3s ease, color 0.3s ease;
          font-weight: 400;
          &.label-in-input {
            color: $color-primary;
            top: 14px;
            left: 20px;
            cursor: text;
          }
          &.label-above-input {
            color: $color-white;
            top: -21px;
            left: 10px;
          }
        }
      }
      #arrowBtn {
        background: url('../../../assets/images/arrow-mail-input-mobile.png');
        border-radius: 0px 10px 10px 0px;
        width: 50px;
        height: 50px;
        border: 0;
        outline: 0;
        &.disabled {
          cursor: not-allowed;
          opacity: 0.7;
        }
      }
      #mailInput {
        border-radius: 10px 0px 0px 10px;
        margin-right: unset;
        width: 100%;
        height: 50px;
        font-family: $font-secondry;
        font-size: 18px;
        font-style: normal;
        padding: 4px 0px 0px 20px;
        border: 1px solid $color-link-water;
        font-weight: 400;
        outline: 0;
        margin-left: -2px;
        color: $color-primary;
      }
    }
  }
  .footer-useful-links {
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .static-text {
      color: $color-white;
      font-size: 14px;
      font-family: $font-secondry;
      margin-bottom: 8px;
    }
    ul {
      display: flex;
      list-style: none;
      justify-content: center;
      width: 100%;
      color: $color-mobile-link;
      flex-wrap: wrap;
      padding: 0px 8px 0px 8px;
      margin-bottom: 6px;
      li {
        @media screen and (min-width: 720px) and (max-width: 1280px) {
          padding-left: 15px;
          padding-right: 15px;
        }
        padding-left: 5px;
        padding-right: 5px;
        white-space: nowrap;
        line-height: 17px;
        margin-bottom: 10px;
        font-size: 15px;
        font-family: $font-secondry;
        border-right: 1px solid $color-mobile-link;
        &:last-child {
          border: 0;
        }
        a {
          color: $color-mobile-link;
          text-decoration: none;
          letter-spacing: 0.5px;
        }
      }
    }
  }
  .social-icons {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 28px 21px;
    .icon_img {
      transition: transform 0.1s ease-in-out;
    }
    .icon_img:hover {
      transform: scale(1.1);
    }
  }
  .white-bottom-text {
    background-color: $color-white;
    color: $color-primary;
    font-family: $font-secondry;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 10px;
    padding-bottom: 5px;
    text-wrap: balance;
    min-height: 100px;
    height: auto;
    font-size: 14px;
  }
  .logo_mobile {
    align-self: center;
    padding: 30px 0px 10px 0px;
    @media screen and (max-width: 768px) {
      width: 50%;
    }
    @media screen and (max-width: 426px) {
      width: 40%;
    }
  }
  .accordion-section {
    padding-right: 20px;
    padding-left: 20px;
    align-self: flex-start;
    width: 100%;
    .accordion {
      --bs-accordion-bg: $color-primary;
      --bs-accordion-border-radius: 0;
      --bs-accordion-inner-border-radius: 0;
      padding-bottom: 10px;
      .accordion-item {
        border-bottom: 1px solid $color-chambray;
        border-left: 0;
        border-right: 0;
        border-top: 0;
        background-color: unset;
        &:first-of-type {
          .accordion-button {
            border: 0;
            border-radius: 0;
            padding-bottom: 10px;
            padding-top: 10px;
          }
        }
        .accordion-header {
          border: none;
          margin-top: 0;
          margin-bottom: 0;
          margin-left: -16px;
          .accordion-button {
            color: $color-white;
            font-size: 22px;
            line-height: 29.79px;
            letter-spacing: 0.9px;
            font-family: $font-primary;
            font-weight: 500;
            background: inherit;
            &:focus {
              z-index: 3;
              border-color: unset;
              outline: 0;
              box-shadow: unset;
              background: inherit;
            }
            &::after {
              color: $color-white;
              text-rendering: auto;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              -webkit-transition: all 0.3s ease-in-out;
              transition: all 0.4s ease-in-out;
              background-image: url('../../../assets/images/down-arrow.png');
              position: absolute;
              top: 50%;
              right: 2px;
              margin-top: -7px;
              background-size: 1rem !important;
            }
            &:not(.collapsed) {
              background-color: inherit;
              box-shadow: unset;
              &::after {
                margin-top: -20px !important;
                margin-right: 5px !important;
              }
            }
          }
        }
        .accordion-collapse {
          transition: height 0.5s ease;
          .accordion-body {
            padding: 2px 0px 5px 0px;
            ul {
              list-style: none;
              color: $color-white;
              margin-left: -28px;
              li {
                padding-bottom: 12px;
                a {
                  text-decoration: underline;
                  color: inherit;
                  font-size: 18px;
                  font-family: $font-secondry;
                }
              }
            }
          }
        }
      }
    }
  }
  & .inputNew {
    margin: 0;
    & .outlineInput {
      border-radius: 8px 0px 0px 8px;
      height: 50px;
    }
    & label {
      top: -4px;
      color: $color-primary;
    }
  }
  & .alert.zim-alert.alert-danger {
    margin-top: 20px;
  }

  & .captcha-box {
    margin-right: 0;
    margin-left: 0;
    & label {
      padding-top: 35px;
      color: $color-white;
      @media screen and (max-width: 1280px) {
        padding-top: unset;
        margin: 15px 0px 0px 25px;
      }
    }
  }
  #ZimCaptchaIdFooterMobile {
    @media screen and (max-width: 1280px) {
      margin: 15px 0px 0px 25px;
    }
  }
  & .footer-newsletter {
    & .inputNew .outlineInput {
      background-color: $color-white;
    }
    & .consent-row {
      margin-top: 10px;
      & .col-1 {
        width: 30px;
        margin-right: 10px;
        & .c-check {
          & input[type='checkbox'] {
            margin-top: 5px;
            width: 30px;
            height: 30px;
            border: 1.5px solid $color-link-water;
            border-radius: 5px;
          }
          & input:checked[type='checkbox'] {
            border: 0;
            background-color: $color-white;
            background-image: url('../../../assets/images/checkbox-checked-blue.png');
            background-size: auto;
          }
        }
      }
      & .c-desc {
        width: 85%;
        font-family: $font-secondry;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        @media screen and (min-width: 320px) and (max-width: 320px) {
          width: 80%;
        }
      }
    }
  }
}
