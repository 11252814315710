@use "/src/assets/variables.scss" as *;

.alert.zim-alert{
    --bs-alert-padding-x: 15px;
    --bs-alert-padding-y: 10px;
    & p{
        margin-bottom: 0;
    }
    &.alert-dismissible .btn-close{
        padding: 10px;
    } 
}